<template>
  <el-dialog
    class="verVisible"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="ver-panel" v-if="!isVerified">
      <div class="ver-head">
        <div class="l-title">身份验证</div>
        <div class="l-desc">
          为了保护你的账户安全，请验证身份<br />
          验证成功后进行下一步操作
        </div>
      </div>
      <el-form :model="verifyForm" ref="verifySmsCodeForm" :rules="rules">
        <el-form-item prop="phoneOrEmail">
          <div class="ver-phone-cell">使用手机+{{ phone }}验证</div>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="verifyForm.code" placeholder="请输入短信验证码">
            <el-button
              @click="sendVerificationCode"
              :disabled="sended"
              slot="suffix"
              class="btn btn-getcode"
              >{{
                sended ? `${countDown}秒后再次发送` : "获取短信验证码"
              }}</el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="ver-foot">
            <el-button class="btn-submit" type="primary" @click="submitForm1"
              >验证</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="ver-panel" v-else>
      <div class="ver-head">
        <div class="l-title">更换密码</div>
        <div class="l-desc">规则：长度6-16位字符，必须包含数字和字母</div>
      </div>
      <el-form :model="changeForm" :rules="rules2" ref="changeForm">
        <el-form-item prop="password">
          <el-input
            v-model="changeForm.password"
            type="password"
            placeholder="请输入新密码"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="repassword">
          <el-input
            v-model="changeForm.repassword"
            placeholder="请再次输入新密码"
            type="password"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="ver-foot">
            <el-button class="btn-submit" type="primary" @click="submitForm2"
              >提交</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import {
  getUserInfoData,
  sendUpdatePhoneSms,
  checkUserOldPhoneAndCode,
  sendVerificationCode,
  updatePhone,
  setPassword,
} from "@/service/index.js";
import { Loading } from "element-ui";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    var checkPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("新密码不能为空"));
      }
      const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,16}$/;
      if (!regex.test(value)) {
        return callback(new Error("密码为长度6-16位字符，必须包含数字和字母"));
      }
      callback();
    };
    var checkRePassword = (rule, value, callback) => {
      if (value != this.changeForm.password) {
        return callback(new Error("两次输入的密码不一致"));
      }
      callback();
    };
    return {
      notSend: true,
      verifyForm: {
        code: "",
      },
      changeForm: {
        select: "86",
      },
      phone: "",
      isVerified: false,
      rules: {
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      rules2: {
        password: [
          { required: true, validator: checkPassword, trigger: "blur" },
        ],
        repassword: [
          { required: true, validator: checkRePassword, trigger: "blur" },
        ],
      },
      sended: false,
      countDown: 60,
      countrys: [
        {
          label: "+86 中国",
          val: "86",
        },
        {
          label: "+1 美国 & 加拿大",
          val: "1",
        },
        {
          label: "+886 台湾",
          val: "886",
        },
        {
          label: "+852 香港",
          val: "852",
        },
        {
          label: "+61 澳大利亚",
          val: "61",
        },
        {
          label: "+44 英国",
          val: "44",
        },
        {
          label: "+49 德国",
          val: "49",
        },
        {
          label: "+353 爱尔兰",
          val: "353",
        },
        {
          label: "+41 瑞士",
          val: "41",
        },
        {
          label: "+31 荷兰",
          val: "31",
        },
        {
          label: "+33 法国",
          val: "33",
        },
        {
          label: "+34 西班牙",
          val: "34",
        },
        {
          label: "+64 新西兰",
          val: "64",
        },
        {
          label: "+7 俄罗斯",
          val: "7",
        },
        {
          label: "+82 韩国",
          val: "82",
        },
        {
          label: "+81 日本",
          val: "81",
        },
        {
          label: "+971 阿拉伯联合酋长国",
          val: "971",
        },
      ],
      interval: null,
    };
  },
  mounted() {},
  methods: {
    submitForm1() {
      this.$refs.verifySmsCodeForm.validate((valid) => {
        if (valid) {
          checkUserOldPhoneAndCode({ code: this.verifyForm.code }).then(
            (res) => {
              if (res.success) {
                this.isVerified = true;
                this.interval && clearInterval(this.interval);
                this.sended = false;
                this.countDown = 60;
              } else {
                this.$message.error(res.message);
              }
            }
          );
        }
      });
    },
    submitForm2() {
      this.$refs.changeForm.validate((valid) => {
        if (valid) {
          if (this.changeForm.password != this.changeForm.repassword) {
            return this.$message.warning("两次输入的密码不一致，请重新输入");
          }
          setPassword({
            password: this.changeForm.password,
          }).then((res) => {
            if (res.success) {
              this.interval && clearInterval(this.interval);
              this.$message.success("更换密码成功");
              this.dialogVisible = false;
              this.$emit("reload");
              this.isVerified = false;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    slideValidation(cb) {
      let captchaId = "2060393098";
      const captcha = new TencentCaptcha(captchaId, async (res) => {
        console.log(res);
        if (res.ret === 0) {
          cb();
        }
      });
      captcha.show();
    },
    getUserInfoData() {
      getUserInfoData().then((res) => {
        this.phone = res.result.phone;
        if (!res.result.phone) {
          this.isVerified = true;
        }
      });
    },
    sendVerificationCode() {
      this.slideValidation(() => {
        sendUpdatePhoneSms().then((res) => {
          if (res.success) {
            this.$message.success("发送验证码成功！");
            this.$refs.verifySmsCodeForm.clearValidate();
            this.sended = true;
            this.notSend = false;
            this.interval = setInterval(() => {
              this.countDown = this.countDown - 1;
              if (this.countDown == 0) {
                clearInterval(this.interval);
                this.countDown = 60;
                this.sended = false;
              }
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("change-visible", val);
      },
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.verifyForm = {
          code: "",
        };
        this.changeForm = {
          select: "86",
        };
        this.getUserInfoData();
      }
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.verVisible ::v-deep .el-dialog {
  border-radius: 10px;
}
.verVisible ::v-deep .el-dialog__body {
  padding: 10px 30px;
}
.ver-head {
  height: 80px;
  text-align: center;
  color: #333;
  .l-title {
    font-size: 16px;
  }
  .l-sub-title {
    font-size: 13px;
  }
  .l-desc {
    color: #999;
    margin-top: 10px;
    font-size: 12px;
  }
}
.btn-getcode {
  display: inline-flex;
  border: 0;
  color: #007df7;
  padding: 8px 10px;
  cursor: pointer;
  &.is-disabled {
    color: #c0c4cc;
  }
}
.ver-panel ::v-deep .el-input__inner {
  background: #f8f8f8;
  border-radius: 10px;
  border-color: #f8f8f8 !important;
}
.ver-foot {
  text-align: center;
}
.ver-panel .btn-submit {
  width: 60%;
  margin: 0;
  border-radius: 10px;
}
.ver-panel .btn-submit:not(.is-disabled) {
  background: linear-gradient(90deg, #007bf7 0%, #0aabfb 100%);
}
.flex_bd {
  flex: 1;
  min-width: 0;
}
.input-select-group {
  position: relative;
}
.input-select-group .el-select {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 119;
  width: 120px;
}

.input-select-group .el-input ::v-deep .el-input__inner {
  padding-left: 140px !important;
}
.ver-phone-cell {
  font-size: 14px;
}
</style>
